@import '../../styles/_shared';

.Dashboard {
  @include util__view;

  &__Layout {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    margin: -15px; // Gutter 30px
    width: calc(100% + 30px);
  }

  &__Column {
    width: 10px; // Just for reference
    flex: 2 1 auto;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    &--Wider {
      flex: 3 1 auto;
    }
  }
}
