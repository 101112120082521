@import '../../styles/_shared';

.CustomerDetails {
  @include util__view;

  &__Baselined {
    display: flex;
    align-items: baseline;
  }

  &__ValueContainer {
    @include style__text--small;
    color: $color__white;
    display: flex;
    flex-direction: column;
  }

  &__Weak {
    color: $color__gray--text;
  }

  &__Data {
    border: none;
    padding: 0;
    margin: 0;
    border-collapse: collapse;

    td {
      @include style__text;
      padding: 0 40px 10px 0;
    }

    tr > td:first-child {
      @include font__bold;
    }
  }

  &__Section {
    @include style__h1;
    margin: 100px 0 50px;
    display: flex;
    align-items: center;

    span {
      margin-right: 15px;
    }
  }

  &__Contracts {
    margin: -20px -20px 0;
    width: calc(100% + 40px);
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__Contract {
    width: 270px;
    margin: 20px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
  }

  &__Bookmarked {
    margin: 20px;
  }
}
