.MultiPicker {
  display: flex;
  flex-direction: row;
  margin: -7px;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;


  &--disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &__Item {
    display: flex;
    flex-direction: row;
    margin: 7px;
  }

  &__Display {
    pointer-events: none;
  }
}
