@import '../../styles/_shared';

.HighlightPanel {
  background: $color__white;
  transition: box-shadow 0.2s ease-in-out;
  margin: 30px 0;
  text-decoration: none;
  display: block;
  color: inherit;
  outline: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  }

  &__Head {
    display: flex;
    align-items: center;
    justify-content: stretch;
    padding: 20px;
    background: $color__gray--bg;
    min-height: 70px;
  }

  &__Title {
    @include style__text;
    @include font__bold;
    color: $color__black--text;
    flex: 1 1 auto;
  }

  &__TitlePrefix {
    color: $color__gray--text;
  }

  &__Link {
    text-decoration: none;
    color: $color__black--text;
    transition: color 0.1s ease-in-out;
  }

  &:hover &__Title &__Link,
  &:focus &__Title &__Link {
    color: $color__orange;
  }

  &__Status {
    width: 300px;
    flex: none;
  }

  &__Actions {
    width: 150px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0;
  }

  &__ActionMoveUp {
    margin: 0 10px 0 30px;
  }
}
