@import '../../../styles/_shared';

.ToggleSwitch {
  display: flex;
  align-items: center;
  flex: none;

  &__Label {
    &--Off {
      margin-right: 15px;
    }

    &--On {
      margin-left: 15px;
    }
  }

  &__Toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    height: 28px;
    width: 52px;
    position: relative;
    border-radius: 500px; // High value for pill shape
    transition: background 0.1s ease-in-out;
    cursor: pointer;

    &--On {
      background: $color__orange;

      &:hover {
        background: $color__orange--hover;
      }
    }

    &--Off {
      background: $color__gray--border-light;

      &:hover {
        background: #e7e7e7;
      }
    }
  }

  &__Knob {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $color__white;
    top: 2px;
    left: 2px;
    transition: left 0.2s ease-in-out;
  }

  &__Toggle--On &__Knob {
    left: 26px;
  }

  &__Toggle--Off:hover &__Knob {
    left: 8px;
  }

  &__Toggle--On:hover &__Knob {
    left: 20px;
  }
}
