@import '../../../styles/_shared';

.SingleImage {
  flex: none;
  display: block;
  margin: 10px;
  position: relative;
  font-size: 0;
  line-height: 1;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  background-color: $color__gray--bg; // visible while loading image

  &--Circle {
    border-radius: 50%;
  }

  &:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }

  &--Dragging {
    opacity: 0.5;
    transform: scale(0.8, 0.8);
  }

  &__Image {
    &--Cover {
      object-fit: cover;
    }

    &--Contain {
      object-fit: contain;
      padding: 15px;
      border: 1px $color__gray--border-light solid;
      border-radius: 8px;
      background: $color__white;
    }
  }

  &__Remove {
    position: absolute;
    z-index: 5;
    right: 8px;
    top: 8px;
    opacity: 0.000001;
    transform: scale(0.000001, 0.000001);
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  &--Circle &__Remove {
    right: 15%;
    top: 15%;
  }

  &:not(&--Dragging):hover &__Remove,
  &:not(&--Dragging):focus-within &__Remove {
    opacity: 1;
    transform: scale(1, 1);
  }
}
