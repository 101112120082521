@import '../../styles/_shared';

.UserMenuItem {
  @include style__text--small;
  display: block;
  background: $color__white;
  color: $color__black--text;
  text-decoration: none;
  padding: 7px 30px 7px 15px;
  transition: opacity 0.1s ease-in-out;

  &--Alternate {
    background: $color__gray--bg;
  }

  &:hover {
    opacity: 0.8;
  }
}
