@import '../../styles/_shared';

.Login {
  min-height: 100vh;
  background-color: $color__gray--bg;
  padding: 150px 0;
  text-align: center;

  &__Wrapper {
    background-color: $color__white;
    height: 300px;
    width: 450px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  &__Header {
    @include style__h1;
    padding: 50px 30px 0;
  }

  &__Content {
    @include font__normal;
    padding: 30px 0 0;
    max-width: 300px;
    margin: auto;
  }

  &__Footer {
    padding: 30px 30px 0;
    margin: auto;
  }
}
