@import '../../styles/_shared';

.ContractTile {
  @include style__text;
  display: block;
  width: 100%;
  padding: 30px;
  background: $color__gray--bg;
  color: $color__black--text;

  &--Highlight {
    background: $color__orange;
    color: $color__white;
  }

  &__CustomerId {
    @include style__h2;
    margin-bottom: 10px;
  }

  &__Type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  &__Type *:not(:first-child) {
    margin-left: 10px;
  }
}
