@import '../../styles/shared';

.DashboardTile {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  margin: 15px 0;

  &--WithDetails {
    flex: 1 1 auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }

  &__Tile {
    display: flex;
    flex-direction: column;
    flex: none;
    background-color: $color__orange;
    text-align: center;
    padding: 30px;
    transition: background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      background: $color__orange--hover;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }
  }

  &__Value {
    @include style__h1;
    color: $color__white;
    align-self: center;
  }

  &__Tooltip {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  &__MainLabel {
    @include style__h2;
    color: $color__white;
    margin-bottom: 5px;
  }

  &__SubLabel {
    @include style__text--small;
    color: $color__white;
  }

  &__DetailsWrapper {
    flex: 1 1 auto;
    background: $color__gray--border-light;
    position: relative;
  }

  &__Details {
    @include util__absolute-coverage;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
