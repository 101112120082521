@import '../../styles/_shared';

.SingleBarChart {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  &__BarContainer {
    flex: 1 1 auto;
    position: relative;

    &--WHITE {
      background: $color__white;
    }

    &--GRAY {
      background: $color__gray--bg;
    }
  }

  &__Bar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: $color__orange;
  }

  &__Value {
    flex: none;
    margin-left: 15px;
    width: 60px;
    line-height: 1.4;
  }
}
