@import '../../styles/_shared';

.UserMenu {
  @include style__text--small;
  position: relative;

  hr {
    opacity: 0.2;
  }

  &__Dropdown {
    position: absolute;
    background: $color__white;
    border: 2px $color__gray--bg solid;
    border-radius: 8px;
    z-index: 10;
    right: 0;
    top: 65px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    transform-origin: center top;
    transform: scaleY(0);
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out;

    &--Visible {
      opacity: 1;
      visibility: visible;
      transform: scaleY(1);
    }
  }
}
