@use 'sass:color';

@import '../styles/shared';

/* Toast styling */
div.Toastify__toast {
  @include font__bold;
  font-size: 14px;
  line-height: 22px;
  border-radius: 8px;
  padding: 15px;
  color: $color__black--text;

  &--success {
    background-color: color.change($color__green, $lightness: 95%);
  }

  &--warning {
    background-color: color.change($color__orange, $lightness: 95%);
  }

  &--error {
    background-color: color.change($color__orange--alert, $lightness: 95%);
  }
}
