@import '../../styles/_shared';

.Chart {
  position: relative;

  &__EmptyOverlay {
    z-index: 1000;
    position: absolute;
    inset: 0;
    background-color: rgba(244, 244, 244, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > span {
      @include font__light;
      font-size: 1.5rem;
      color: $color__gray--text;
      opacity: 0.5;
    }
  }
}
