@import '../../styles/_shared';

.Resources {
  @include util__view;

  &__Identifier {
    @include style__text--small;
    @include font__bold;
  }

  &__Label {
    @include style__text--small;
    color: $color__gray--text;
    display: block;
    margin-top: 5px;
  }
}
