// Contains common styles for inputs
@import '../../../styles/shared';

@mixin Input__Wrapper() {
  position: relative;
  width: 100%;
  padding: 10px 15px; // Slimmer in CMS than in frontend to save space
  border: 1px $color__gray--border-light solid;
  border-radius: 8px;
}

@mixin Input__Label() {
  @include style__text--label;
  color: $color__gray--text;
  position: absolute;
  left: 12px;
  top: -6px;
  background: $color__white;
  padding: 0 3px;
  display: flex;
  align-items: center;

  & > span + * {
    margin-left: 5px;
  }
}

@mixin Input__Validation($visible: false) {
  @include style__text--label;
  color: $color__orange;
  position: absolute;
  right: 12px;
  top: -6px;
  background: $color__white;
  padding: 0 3px;
  display: none;

  @if $visible {
    display: block;
  }
}

@mixin Input__Textbox($readonly: false, $textarea: false) {
  @include style__text;
  color: $color__black--text;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;

  @if $readonly {
    padding-right: 40px; // Account for readonly badge
    color: $color__gray--text;
    caret-color: transparent;
  }

  @if $textarea {
    height: 100px;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: $color__gray--text;
    background: none;
    caret-color: transparent;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba($color__gray--text, 0.3);
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba($color__gray--text, 0.3);
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba($color__gray--text, 0.3);
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: rgba($color__gray--text, 0.3);
  }
}

@mixin Input__RightCircle() {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
