@import '../../styles/_shared';

.ResourceEdit {
  @include util__view;

  &__Label {
    @include style__text--small;
    color: $color__gray--text;
    display: block;
    margin-top: 5px;
  }

  &__Substitution {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
}
