@import '../../styles/_shared';


.LoadingStrip {
  display: block;
  width: 100px;
  height: 1.25em;
  overflow: hidden;
  background: rgba(0, 0, 0, 3%);
  border-radius: 5px;

  &--Inverted {
    background: rgba(255, 255, 255, 13%);
  }

  &__Marquee {
    width: 500%;
    height: 100%;
    background: linear-gradient(40deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 15%), rgba(0, 0, 0, 0));
    animation: LoadingStrip__Marquee 2s linear 0s infinite normal;

    &--Inverted {
      background: linear-gradient(40deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 33%), rgba(255, 255, 255, 0));
    }
  }
}

@keyframes LoadingStrip__Marquee {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(400%);
  }
}
