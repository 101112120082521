@import '../../../styles/_shared';

.TableCell {
  @include style__text;
  vertical-align: top;
  padding: 25px 30px;
  background: $color__white;

  &--Slim {
    padding: 25px 10px;
  }

  &--Alternate {
    background: $color__gray--bg;
  }

  /* Alignment */
  &--LEFT {
    text-align: left;
  }

  &--CENTER {
    text-align: center;
  }

  &--RIGHT {
    text-align: right;
  }
}
