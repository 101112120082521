@import '../../styles/_shared';

.OfferTypePicker {
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin: 0 -15px;
  width: calc(100% + 30px);

  &__Button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 10px; // Dummy width for same size
    flex: 1 1 auto;
    height: 120px;
    border: 2px $color__orange solid;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: background 0.1s ease-in-out;
    background: $color__white;
    cursor: pointer;
    margin: 0 15px;

    &--Active {
      background: $color__orange;
    }

    &:not(&--Active):hover {
      background: rgba($color__gray--bg, 0.5);
    }
  }

  &__ButtonLabel {
    @include style__text;
    @include font__bold;
    line-height: 1;
    color: $color__orange;
    transition: color 0.1s ease-in-out;
    margin-top: 15px;
  }

  &__Button--Active &__ButtonLabel {
    color: $color__white;
  }
}
