@import 'inputs';

.Textbox {
  @include Input__Wrapper();

  &__Label {
    @include Input__Label();
  }

  &__Input {
    @include Input__Textbox();

    &--Textarea {
      @include Input__Textbox(false, true);
    }
  }

  &--Readonly &__Input {
    @include Input__Textbox(true);

    &--Textarea {
      @include Input__Textbox(true, true);
    }
  }

  &__Validation {
    @include Input__Validation();

    &--Visible {
      @include Input__Validation(true);
    }
  }

  &__Readonly {
    @include Input__RightCircle();
  }
}
