@import '../../styles/_shared';

.TextLink {
  @include style__text;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  border: none;
  background: none;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;
  transition: color 0.1s ease-in-out;
  color: $color__orange;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $color__orange--hover;
  }

  &--InheritSize {
    font-size: inherit;
  }
}
