@import '../../styles/_shared';

@mixin LoadingOverlay__Coverage() {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.LoadingOverlay {
  @include LoadingOverlay__Coverage();
  position: absolute;
  z-index: 9999;
  visibility: hidden;
  background: rgba($color__white, 0.5);
  overflow: hidden;
  transition: background 0.2s ease-in-out;

  &--Visible {
    visibility: visible;
  }

  &--Clear {
    background: transparent;
  }

  &__ProgressBar {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 0;
    z-index: 9999;
    background: rgba($color__orange, 0.3);
    transition: height 0.2s ease-in-out;

    &::before {
      content: '';
      display: block;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: $color__orange;
      animation: LoadingOverlay__ProgressBarMarquee 1.5s ease-in-out infinite;
    }
  }

  &--Visible &__ProgressBar {
    height: 4px;
  }
}

@keyframes LoadingOverlay__ProgressBarMarquee {
  0% {
    transform: translateX(100%);
    opacity: 0.5;
  }

  50% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    opacity: 0.5;
  }
}
