@import '../../styles/_shared';

.SearchBar {
  position: relative;
  width: 300px;

  &__Textbox {
    padding-right: 30px !important;
  }

  &__Submit {
    position: absolute;
    z-index: 5;
    right: 10px;
    top: 10px;
  }
}
