@import '../../styles/_shared';

.Campaigns {
  @include util__view;

  &__Archive {
    @include style__text;
    color: $color__gray--text;
    margin-top: 60px;
    border-top: 1px $color__gray--border-light solid;
    padding: 20px;
    text-align: center;
  }
}
