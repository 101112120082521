@import '../../styles/shared';

.NavBar {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 5;
  position: relative;

  &__HeaderContent {
    display: flex;
    align-items: center;
    justify-content: stretch;
    height: 80px;
  }

  &__Logo {
    width: 90px;
    margin-right: 40px;
    flex: none;
  }

  &__NavBar {
    flex: 1 1 auto;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__User {
    margin-right: 25px;
  }

  &__Environment {
    @include style__text--small;
    background: $color__gray--bg;
    border-radius: 500px;
    color: $color__gray--text;
    padding: 0 5px 0 10px;
    display: flex;
    align-items: center;

    &--Live {
      background: $color__orange;
      color: $color__white;
    }
  }

  &__EnvironmentLabel {
    margin-right: 5px;
  }
}
