@import '../../styles/_shared';

.OfferPanel {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  padding: 20px 20px 20px 0;
  margin-left: 30px;
  border-bottom: 1px $color__gray--border-light solid;

  &--WithoutCampaign {
    margin-left: 0;
    padding: 20px;
    background: rgba($color__gray--bg, 0.25);
  }

  &--Alternate {
    background: $color__gray--bg;
  }

  &:last-child {
    border-bottom: none;
  }

  &__Title {
    flex: 1 1 auto;
  }

  &__TitleText {
    @include style__text;
    @include font__bold;
    text-decoration: none;
    color: $color__black--text;
    transition: color 0.1s ease-in-out;
    outline: none;
    cursor: pointer;

    &:hover,
    &:focus-visible {
      color: $color__orange;
    }

    &--Unnamed {
      @include style__text;
      @include font__light;
      color: $color__gray--text;
      text-decoration: none;
      transition: color 0.1s ease-in-out;
    }
  }

  &__Partner {
    @include style__text--small;
    @include font__bold;
    color: $color__gray--text;
  }

  &__Status {
    width: 300px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__StatusLabel {
    @include style__text--small;
    margin-left: 15px;

    &--Inactive {
      color: $color__gray--text;
    }
  }

  &__Actions {
    width: 150px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0;
  }

  &__ActionMoveUp {
    margin: 0 10px 0 30px;
  }
}
