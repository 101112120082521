@import '../../styles/_shared';

.ImagePreview {
  &__NoImage {
    @include font__normal;
    color: $color__gray--text;
  }

  &__Wrapper {
    margin: -10px -10px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__ImageLink {
    flex: none;
    display: block;
    margin: 10px;
    position: relative;
    font-size: 0;
    line-height: 1;
    overflow: hidden;
    border-radius: 8px;

    &--Circle {
      border-radius: 50%;
    }
  }

  &__ImageLink:hover &__Circle,
  &__ImageLink:focus-within &__Circle {
    opacity: 1;
    transform: scale(1, 1);
  }

  &__Circle {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
    opacity: 0.000001;
    transform: scale(0.000001, 0.000001);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  &__Image {
    transition: opacity 0.2s ease-in-out;

    &--Cover {
      object-fit: cover;
      background: $color__gray--bg;
    }

    &--Contain {
      object-fit: contain;
      padding: 15px;
      border: 1px $color__gray--border-light solid;
      border-radius: 8px;
      background: $color__white;
    }
  }

  &__ImageLink:focus-within &__Image {
    opacity: 0.50;
  }

  &__ImageLink:hover &__Image {
    opacity: 0.75;
  }
}
