@import '../../styles/_shared';

.SitePanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 10px;

  &__Extend {
    flex: 1;
  }

  &__Standard {
  }

  &__OverlayTitle {
    margin-bottom: 30px;
  }

  &__OverlayHint {
    margin-top: 1rem;
  }

  &__HintText {
    @include style__text--small;
    color: $color__gray--text;

    &--Success {
      @include font__bold;
      color: $color__green;
    }
  }

  &__OverlayButton {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
