@import '../../styles/shared';

.OfferPreview {
  @include util__view;

  &__Error {
    padding: 50px 0;
    text-align: center;
  }

  &__ErrorTitle {
    @include style__h2;
    margin-bottom: 30px;
  }

  &__ErrorText {
    @include style__text;
  }

  &__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__Logo {
    height: 40px;
    width: auto;
  }

  &__Intro {
    background: $color__gray--bg;
    padding: 20px;
    margin: 40px 0 60px;
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
  }

  &__IntroContent {
    flex: 1 1 auto;
    padding-left: 20px;
  }

  &__IntroHeadline {
    @include style__text;
    @include font__bold;
    margin-bottom: 5px;
  }
  &__IntroText {
    @include style__text--small;
    color: $color__gray--text;
  }

  &__Title {
    @include style__h1;
    margin-bottom: 40px;
  }

  &__Type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__TypeLabel {
    margin-left: 15px;
  }

  &__PartnerHeadline {
    @include style__h2;
    @include font__bold;
  }

  &__PartnerDescription {
    margin-top: 15px;
  }

  &__Footer {
    @include style__text--small;
    text-align: center;
    margin-top: 60px;
    color: $color__gray--text;
  }
}
