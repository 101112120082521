@import './inputs';

.RadioButtons {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__Option {
    @include style__text;
    margin: 0 30px 0 0;
    color: $color__black--text;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__Option:not(&__Option--Selected):hover &__Indicator {
    border-color: $color__orange;
  }

  &__Option--Selected &__Indicator {
    &::before {
      opacity: 1;
      transform: scale(1, 1);
    }
  }

  &__Indicator {
    width: 26px;
    height: 26px;
    display: block;
    border: 1px $color__gray--border solid;
    border-radius: 50%;
    margin-right: 10px;
    position: relative;
    transition: border-color 0.1s ease-in-out;

    &::before {
      // Orange dot
      content: '';
      display: block;
      position: absolute;
      left: 5px;
      top: 5px;
      right: 5px;
      bottom: 5px;
      background: $color__orange;
      border-radius: 50%;
      opacity: 0;
      transform: scale(0, 0);
      transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    }
  }

  &--Disabled &__Option,
  &--Readonly &__Option {
    color: $color__gray--text;
    pointer-events: none;
  }
}
