@import '../../styles/_shared';

.Pagination {
  margin: 60px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &__NumberedPages {
    margin: 0 30px;
  }

  &__Ellipsis,
  &__Page {
    @include style__text;
    color: $color__black--text;
    display: inline-block;
    margin: 0 7px;
  }

  &__Page {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: none;
    transition: color 0.1s ease-in-out;

    &:hover {
      color: $color__orange--hover;
    }

    &--Current {
      color: $color__orange;
      text-decoration: underline;
    }
  }
}
