.Container {
  max-width: 1220px;
  min-width: 1220px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;

  &--Flexible {
    max-width: 100%;
    width: 1220px;
    min-width: 800px;
    padding: 0 30px;
  }
}
