@import '../../styles/_shared';

.CampaignStatistics {
  @include util__view;

  &__Section {
    @include style__h2;
    margin: 70px 0 30px;
  }

  &__Screen {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    margin-top: 40px;
  }

  &__ScreenItem {
    text-align: center;
    padding: 30px;
    color: $color__white;

    &--Orange {
      background: $color__orange;
    }

    &--Gray {
      background: $color__gray--border;
    }
  }

  &__ScreenValue {
    @include font__bold;
    @include style__h1;
  }

  &__ScreenLabel {
    @include font__bold;
    @include style__h2;
  }

  &__Weak {
    color: $color__gray--text;
  }
}
