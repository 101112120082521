@import '../../../styles/_shared';
@import 'inputs';

.DatePicker {
  @include Input__Wrapper();
  padding: 8px 15px;

  &__Label {
    @include Input__Label();
  }

  &__Validation {
    @include Input__Validation();

    &--Visible {
      @include Input__Validation(true);
    }
  }

  // Overwrite styles of airbnb/react-date
  .SingleDatePicker {
    width: 100%;
  }

  .SingleDatePicker_picker {
    top: 46px !important;
    left: -15px !important;
  }

  .DateInput_fang {
    top: 32px !important;
    left: 214px !important;
  }

  .DateInput,
  .DateInput_input,
  .SingleDatePickerInput {
    background: none;
  }

  .SingleDatePickerInput {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: stretch;
    padding-right: 0 !important;
  }

  .DateInput {
    flex: 1 1 auto;
  }

  .DateInput_input {
    @include Input__Textbox();
  }

  .DateInput_input__disabled {
    font-style: normal;
  }

  .SingleDatePickerInput_calendarIcon {
    padding: 0;
    margin: 0;
    transition: opacity 0.1s ease-in-out;
  }

  .SingleDatePickerInput_clearDate {
    position: static;
    transform: none;
    font-size: 0;
    line-height: 1;
    margin-right: 5px;
    transition: opacity 0.1s ease-in-out;
  }

  .SingleDatePickerInput__disabled .SingleDatePickerInput_calendarIcon,
  .SingleDatePickerInput__disabled .SingleDatePickerInput_clearDate {
    opacity: 0.3;
    pointer-events: none;
  }

  .CalendarDay__default:hover {
    background: $color__gray--bg;
  }

  .CalendarDay__selected {
    background-color: $color__orange;
    border-color: $color__orange;
  }
}
