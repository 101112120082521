@import '../../../styles/_shared';

.ImageUpload {
  border: 2px $color__orange solid;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;
  background-color: $color__white;
  cursor: pointer;
  margin: 10px;
  position: relative;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  &:not(&--Dragging):focus-visible {
    border-color: mix($color__orange, $color__white, $ratio__focus);
  }

  &:not(&--Dragging):hover {
    border-color: mix($color__orange, $color__white, $ratio__hover);
  }

  &--Circle {
    border-radius: 50%;
  }

  &--Dragging {
    background-color: $color__orange;
  }

  &--Uploading {
    border-color: rgba($color__orange, 0.1);
  }

  &__Label {
    @include style__text;
    @include font__bold;
    line-height: 1;
    color: $color__orange;
    transition: color 0.1s ease-in-out;
    margin-top: 15px;
    padding: 0 10px;
    text-align: center;
  }

  &--Dragging &__Label {
    color: $color__white;
  }

  // Uploading overlay
  &__Uploading {
    @include util__absolute-coverage;
    z-index: 5;
    background: rgba($color__white, 0.9);
    opacity: 0;
    visibility: hidden;
    display: flex;
    line-height: 1;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

    &--Visible {
      opacity: 1;
      visibility: visible;
    }
  }
}
