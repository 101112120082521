.FormRow {
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin: 0 -20px;
  width: calc(100% + 40px);

  &--Spaced {
    justify-content: space-between;
  }

  &__Element {
    width: 10px; // Only for scaling
    flex: 1 1 auto;
    margin: 0 20px;
  }

  &--Spaced &__Element {
    width: auto;
    flex: none;
  }

  &--LastFixed &__Element:last-child {
    width: auto;
    flex: 0 0 auto;
  }
}
