@import '../../styles/_shared';

@mixin gradient($color) {
  background: linear-gradient(to bottom, rgba($color, 0) 0%, rgba($color, 0.7) 40%, $color 80%);
}

.AutoEllipsis {
  &__Collapse {
    position: relative;
    margin-bottom: 10px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 30px;
    }

    &--GRAY::after {
      @include gradient($color__gray--bg);
    }

    &--WHITE::after {
      @include gradient($color__white);
    }
  }

  &__Content {
    padding-bottom: 30px;
  }
}
