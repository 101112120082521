@import './inputs';

.RadioButtonBar {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;

  &__Option {
    @include style__text--button;
    text-align: center;
    cursor: pointer;
    transition: opacity 0.1s ease-in-out, background 0.1s ease-in-out, color 0.1s ease-in-out;
    padding: 14px 20px;
    width: auto;
    // Non-selected
    background: $color__white;
    border-top: 2px $color__orange solid;
    border-bottom: 2px $color__orange solid;
    border-left: 1px $color__orange solid;
    border-right: 1px $color__orange solid;
    color: $color__orange;

    // Special rules for merging
    &:first-child {
      border-radius: 8px 0 0 8px;
      border-left-width: 2px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
      border-right-width: 2px;
    }

    &:not(&--Selected):hover {
      background: rgba($color__gray--bg, 0.5);
    }

    &--Selected {
      color: $color__white;
      background: $color__orange;
    }
  }

  &--Disabled &__Option,
  &--Readonly &__Option {
    opacity: 0.3;
    pointer-events: none;
  }
}
