@import './inputs';

.Checkbox {
  @include style__text;
  color: $color__black--text;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;

  &:not(&--OnlyDisplay) {
    cursor: pointer;
  }

  & + & {
    // Two checkboxes on top of each other have smaller gap
    margin-top: 15px;
  }

  &:not(&--OnlyDisplay):hover &__Indicator {
    border-color: $color__orange;
  }

  &--Ticked &__Tick {
    opacity: 1;
    transform: scale(1, 1);
  }

  &__Indicator {
    flex: none;
    width: 26px;
    height: 26px;
    border: 1px $color__gray--border solid;
    background: $color__white;
    border-radius: 4px;
    position: relative;
    transition: border-color 0.1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__Tick {
    margin-top: 2px;
    opacity: 0;
    transform: scale(0, 0);
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  &--Disabled,
  &--Readonly {
    color: $color__gray--text;
    pointer-events: none;
  }

  &__Label {
    margin-left: 10px;
  }
}
