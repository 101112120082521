@import '../../styles/_shared';

.OfferEdit {
  @include util__view;

  &__Type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__TypeLabel {
    margin-left: 15px;
  }

  &__TypeLabelAddition {
    color: $color__gray--text;
    margin-left: 5px;
  }

  &__GeoRestriction {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__Limit {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__LimitPicker {
    margin-left: -15px;
    width: 320px;
  }

  &__Section {
    @include style__h1;
    margin: 100px 0 50px;
  }

  &__ShopifyTryOut {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px;
  }

  &__FormUrlCodeReplacement {
    margin-top: 30px;
  }

  &__SiteName {
  }

  &__SiteAddress {
    @include style__text--small;
  }
}
