@import '../../../styles/_shared';

.DropArea {
  transition: width 0.2s ease-in-out, opacity 0.2s ease-in-out;
  border-radius: 8px;
  z-index: 2;
  position: relative;
  width: 0;
  margin: 10px 0;
  opacity: 1;
  background: $color__gray--bg;

  &__Drop {
    position: absolute;
    top: -20px;
    bottom: -20px;
    transition: all 0.2s ease-inout;
    transition-delay: 0.2s;
  }

  &--Standby &__Drop {
    visibility: hidden;
    pointer-events: none;
  }

  &__Indicator {
    position: absolute;
    left: -2px;
    width: 4px;
    top: -10px;
    bottom: -10px;
    background: $color__orange;
    border-radius: 2px;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    transform-origin: center center;
    opacity: 0;
    transform: scaleY(0);
    pointer-events: none;
  }

  &--Ready &__Indicator {
    transform: scaleY(1);
    opacity: 1;
  }
}
