@import './inputs';

.NumericPicker {
  @include Input__Wrapper();
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__Label {
    @include Input__Label();
  }

  &__Value {
    @include Input__Textbox();
  }

  &__Buttons {
    display: flex;
    align-items: center;
    font-size: 0;
    line-height: 1;
  }

  &__Button {
    &--Plus {
      margin-left: 15px;
    }
  }

  &--Disabled &__Value {
    @include Input__Textbox(true);
  }
}
