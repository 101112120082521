@import '../../styles/_shared';

.ValueDashboard {
  @include util__view;

  &__Section {
    @include style__h2;
    margin: 70px 0 30px;
  }

  &__Legend {
    @include style__text;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &__Item {
      display: flex;
      flex-direction: row;

      &--Category {
        width: 90px;
      }

      &--Value {
        width: 45px;
        text-align: end;
      }
    }
  }

  &__ColorBox {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 0.3rem;
    display: inline-block;
    width: 40px;
    height: 1rem;
  }

  &__Dual {
    display: flex;
    flex-direction: row;

    &--Item {
      padding: 20px;
    }

    &--Fill {
      flex: 1;
    }
  }

  &__MediumWidth {
    @include style__text;
    width: 60%;
  }
}
