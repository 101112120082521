@import 'styles/_shared';

html,
body {
  @include font__normal;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  image-rendering: auto;
  color: $color__black--text;
}

body {
  min-height: 500px;
}

* {
  box-sizing: border-box;
}

b,
strong {
  @include font__bold;
}

a {
  color: $color__orange;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}
