@import '../../styles/_shared';

.Tooltip {
  position: relative;

  &__Icon {
    cursor: pointer;
  }

  &__Content {
    @include style__text--tooltip;
    position: absolute;
    top: -5px;
    background: $color__gray--strong;
    z-index: 20;
    border-radius: 8px;
    padding: 10px 15px;
    color: $color__white;
    min-width: 200px;
    max-width: 300px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    transition-delay: 0.25s; // For fade out

    &--Visible {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s; // Instant fade in
    }
  }
}
