@import '../../styles/_shared';

.DashboardTileItem {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  padding: 20px 25px;
  background: $color__white;

  &--Alternate {
    background: $color__gray--bg;
  }

  &__Text {
    @include style__text;
    flex: 1 1 auto;
    padding-right: 20px;
  }

  &__SubLabel {
    color: $color__gray--text;
  }

  &__Buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: none;

    & > * {
      &:not(:first-child) {
        margin-left: 15px;
      }
    }
  }
}
