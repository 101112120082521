@import 'fonts';

/* COLORS */
$color__black--text: #212529;
$color__gray--bg: #f5f5f5;
$color__gray--border: #b3b3b3;
$color__gray--border-light: #e0e0e0;
$color__gray--strong: #616161;
$color__gray--text: #9e9e9e;
$color__green: #74b841;
$color__orange: #f39100;
$color__orange--alert: #f8522a;
$color__orange--hover: #f8a01b;
$color__white: #ffffff;

/* MIX RATIOS */
$ratio__focus: 50%;
$ratio__hover: 75%;

/* FONT STYLES */
@mixin style__h1() {
  @include font__bold;
  font-size: 42px;
  line-height: 54px;
  padding: 0;
  margin: 0;
}

@mixin style__h2() {
  @include font__bold;
  font-size: 26px;
  line-height: 32px;
  padding: 0;
  margin: 0;
}

@mixin style__text() {
  @include font__normal;
  font-size: 16px;
  line-height: 26px;
  padding: 0;
  margin: 0;
}

@mixin style__text--small() {
  @include font__normal;
  font-size: 14px;
  line-height: 22px;
  padding: 0;
  margin: 0;
}

@mixin style__text--label() {
  @include font__normal;
  font-size: 12px;
  line-height: 12px;
}

@mixin style__text--button() {
  @include font__bold;
  font-size: 16px;
  line-height: 16px;
}

@mixin style__text--tooltip() {
  @include font__normal;
  font-size: 12px;
  line-height: 20px;
}

/* UTILITIES */
@mixin util__absolute-coverage() {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

@mixin util__view() {
  padding: 70px 0 150px;
  position: relative;
}
