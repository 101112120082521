@import '../../styles/_shared';

.Button {
  @include style__text--button;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 100%;
  max-width: 270px;
  border: none;
  background: none;
  text-decoration: none;
  padding: 14px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out, border-color 0.1s ease-in-out;

  &:focus-visible {
    outline: none;
  }

  &__Label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--AutoWidth {
    width: auto;
    padding: 14px 20px;
  }

  &--HasIcon {
    display: flex;
    flex: none;
    align-items: center;
    width: auto;
    padding: 14px 15px 14px 20px;
  }

  &--HasIcon &__Label {
    margin-right: 15px;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &--Style {
    // Font color
    &-ORANGE_FILL,
    &-WHITE_OUTLINE {
      color: $color__white;
    }

    &-ORANGE_OUTLINE,
    &-WHITE_FILL,
    &-GHOST, {
      color: $color__orange;
    }

    // Background
    &-ORANGE_FILL {
      background-color: $color__orange;

      &:focus-visible {
        background-color: mix($color__orange, $color__white, $ratio__focus);
      }

      &:hover {
        background-color: mix($color__orange, $color__white, $ratio__hover);
      }
    }

    &-WHITE_FILL {
      background-color: $color__white;

      &:focus-visible {
        background-color: mix($color__white, $color__orange, $ratio__focus);
      }

      &:hover {
        background-color: mix($color__white, $color__orange, $ratio__hover);
      }
    }

    // Border
    &-ORANGE_FILL {
      border-width: 2px;
      border-style: solid;
      border-color: transparent;
    }

    &-ORANGE_OUTLINE {
      border-width: 2px;
      border-style: solid;
      border-color: $color__orange;

      &:focus-visible {
        border-color: mix($color__orange, $color__white, $ratio__focus);
      }

      &:hover {
        border-color: mix($color__orange, $color__white, $ratio__hover);
      }
    }

    &-WHITE_FILL {
      border: none;
    }

    &-WHITE_OUTLINE {
      border-width: 2px;
      border-style: solid;
      border-color: $color__white;

      &:focus-visible {
        border-color: mix($color__white, $color__orange, $ratio__focus);
      }

      &:hover {
        border-color: mix($color__white, $color__orange, $ratio__hover);
      }
    }

    &-GHOST, {
      border-width: 2px;
      border-style: dashed;
      border-color: $color__orange;

      &:focus {
        border-color: mix($color__orange, $color__white, $ratio__focus);
      }

      &:hover {
        border-color: mix($color__orange, $color__white, $ratio__hover);
      }
    }
  }
}
