@import '../../../styles/_shared';
@import './inputs';

.Dropdown {
  position: relative;

  &__CurrentWrapper {
    @include Input__Wrapper();
    cursor: pointer;
    outline: none;

    &:focus,
    &:hover {
      color: $color__orange;
    }
  }

  &__CurrentText {
    transition: color 0.1s ease-in-out;

    &--Null {
      color: $color__gray--text;
    }
  }

  &__Label {
    @include Input__Label();
  }

  &__Caret {
    @include Input__RightCircle();
    transition: transform 0.2s ease-in-out;
    transform-origin: center center;
  }

  &--Open &__Caret {
    transform: rotateZ(180deg);
  }

  &__List {
    border: 1px $color__gray--border-light solid;
    background: $color__white;
    z-index: 10;
    border-radius: 8px;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 110%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    transform-origin: center top;
    transform: scaleY(0);
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
    max-height: 300px;

    &--Open {
      opacity: 1;
      visibility: visible;
      transform: scaleY(1);
    }
  }

  &__Option {
    @include style__text;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: $color__white;
    text-align: left;
    padding: 5px 15px;
    cursor: pointer;
    transition: background 0.1s ease-in-out;
    outline: none;

    &--Null {
      color: $color__gray--text;
    }

    &--Current {
      background: $color__gray--bg;
    }

    &:not(&--Current):hover,
    &:not(&--Current):focus-visible {
      background: rgba($color__gray--bg, 0.5);
    }
  }
}
