@import '../../styles/_shared';

.DisplayGroup {
  display: flex;
  align-items: flex-start;
  justify-content: stretch;
  padding: 30px 0;
  border-bottom: 1px $color__gray--border-light solid;

  *:not(&) + & {
    // First after another element needs top border
    border-top: 1px $color__gray--border-light solid;
  }

  &__LabelWrapper {
    flex: none;
    width: 300px;
    padding-right: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__Label {
    @include style__text;
    @include font__bold;
    margin-right: 10px;
  }

  &__ContentWrapper {
    @include style__text;
    flex: 1 1 auto;
  }
}
