@import '../../../styles/_shared';

.TableHead {
  @include style__text;
  @include font__bold;
  vertical-align: top;
  padding: 25px 30px;
  background: $color__white;
  position: relative;

  &--Slim {
    padding: 25px 10px;
  }

  &--Sortable {
    cursor: pointer;
    padding-right: 50px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  /* Alignment */
  &--LEFT {
    text-align: left;
  }

  &--CENTER {
    text-align: center;
  }

  &--RIGHT {
    text-align: right;
  }

  &__SortIndicator {
    position: absolute;
    right: 30px;
    top: 29px;
    height: 18px;
    width: auto;
  }
}
