.NavBarItem {
  display: inline-block;

  &:not(:last-child) {
    margin-right: 30px;
  }

  &__Link {
    text-decoration: none;
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: center;
    color: #9e9e9e;
    transition: color 0.1s ease-in-out;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0;
      background: #f39100;
      opacity: 0;
      transition: height 0.1s ease-in-out, opacity 0.1s ease-in-out;
    }
  }

  &__Link:hover,
  &__Link:focus-visible,
  &--Current &__Link {
    color: #212529;
  }

  &--Current &__Link::after,
  &__Link:hover::after,
  &__Link:focus-visible::after {
    height: 5px;
    opacity: 1;
  }
}
