@import '../../styles/shared';

.Overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.75);
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 100px 0;

  // Default
  opacity: 0;
  visibility: hidden;
  overflow: scroll;

  &--In {
    opacity: 1;
    visibility: visible;
  }

  &--Out {
    opacity: 0;
    visibility: hidden;
    transition-delay: 0.2s;
  }

  &__Window {
    width: 550px;
    max-width: 90vw;
    background: $color__white;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    padding: 40px;
    // Default
    opacity: 0;
    visibility: hidden;

    &--Big {
      width: 70vw;
    }

    &--In {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition-delay: 0.2s;
    }

    &--Out {
      opacity: 0;
      visibility: hidden;
      transform: translateY(100%);
    }
  }

  &__Close {
    position: absolute;
    right: 40px;
    top: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: none;
    font-size: 0;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
}
