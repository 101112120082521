@import '../../styles/shared';

.ValueDeduction {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__Body {
    align-self: stretch;
    justify-content: space-between;
    flex: 1;
    display: flex;
  }

  &__Weight {
    align-self: center;
    display: block;
    position: relative;
    width: 60px;
    height: 1.5em;
    overflow: clip;

    & svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    & div {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      & span {
        @include font__light;
      }
    }


  }

  &__Content {
    flex: 1;
    align-self: stretch;
    position: relative;
    padding: 22px 12px;
    border-radius: 10px;
    line-height: 1.2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;

    &__Space {
      width: 15px;
    }

    &__Tooltip {
      & > div {
        display: flex;
        flex-direction: column;

        & hr {
          width: 100%;
        }
      }

      position: absolute;
      top: 5px;
      right: 5px;
      padding-left: 5px;
    }
  }

  &__Label {
    @include font__bold;
    flex: 2;
    white-space: nowrap;
    padding-right: 20px;
    text-align: left;
  }

  &__Value {
    flex: none;
  }

  &__Children {
    flex: 3;
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
}
