@import 'inputs';

.RichtextEditor {
  @include Input__Wrapper();

  .mce-content-body.mce-edit-focus {
    outline: none !important;
  }
}

.tox {
  .tox-collection__item-label > * {
    line-height: 1.2;
  }
}
