@import '../../styles/_shared';

.HighlightDetails {
  @include util__view;

  &__Preview {
    display: block;
    width: 120px;
    font-size: 0;
    line-height: 1;
  }

  &__PreviewThumbnail {
    background: $color__gray--bg; // While loading picture
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
  }

  &__PreviewTitle {
    @include style__text--small;
    @include font__bold;
    text-align: center;
    margin-top: 5px;
  }

  &__ContentWrapper {
    display: flex;
    align-items: center;
  }

  &__Content {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 300px;
  }

  &__Badge {
    @include style__text--small;
    @include font__bold;
    color: $color__white;
    background: $color__orange;
    padding: 3px 8px;
  }

  &__Title {
    @include style__h2;
    margin: 10px 0 20px;
  }

  &__Excerpt {
    @include style__text;
  }
}
