@import '../../styles/_shared';

.PreviewLink {
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: 20px;
  background: $color__gray--bg;
  margin-bottom: 30px;

  &__Alias {
    flex: 1 1 auto;
    @include style__text;
    @include font__bold;
    white-space: nowrap;
    padding-right: 15px;
  }

  &__Data {
    flex: 1 1 auto;
    @include style__text--small;
    color: $color__gray--text;
  }

  &__Environment {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > * {
      margin-right: 5px;
    }
  }
  &__EnvironmentName {
    @include style__text--small;
    margin-right: 10px;
  }
}
