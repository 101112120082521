@import '../../styles/_shared';

.EditsProtocol {
  margin: 120px 0 50px;

  &__Title {
    @include style__h1;
  }

  &__DataTable {
    margin-top: 50px;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  &__DataRow:first-child &__DataCell {
    border-top: 1px $color__gray--border-light solid;
  }

  &__DataCell {
    @include style__text;
    padding: 20px 20px 20px 0;
    border-bottom: 1px $color__gray--border-light solid;
    vertical-align: middle;

    &--Name {
      @include font__bold;
    }

    &--Details {
      text-align: right;
      line-height: 1;
    }
  }

  &__ChangesTitle {
    margin-bottom: 30px;
  }

  &__Field {
    @include style__text--small;
    @include font__bold;
  }

  &__RawData {
    font-size: 12px;
    line-height: 14px;
    font-family: monospace;
    display: block;
    overflow: scroll;
    white-space: pre-wrap;
  }
}
