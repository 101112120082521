@import '../../styles/_shared';

.Toolbar {
  margin-bottom: 50px;

  &__Back {
    height: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__Title {
    @include style__h1;

    &:not(&--Inline) {
      margin-bottom: 20px;
    }
  }

  &__Horizontal {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__Left,
  &__Right {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

  &__Right {
    justify-content: flex-end;
    padding-left: 20px;
    flex-shrink: 0;
  }

  &__Group {
    &:not(&--First) {
      margin-left: 30px;
    }
  }
}
